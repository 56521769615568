<template>
  <div
    class="StepBackground"
    :class="{ hasImage: image, blur }"
    :style="style" />
</template>

<script>
import Color from 'color';

export default {
    props: {
        image: {
            type: String
        },
        blur: {
            type: Boolean,
            default: false
        }
    },
    inject: {
        theme: {
            default: {
                headerBgColor: 'black'
            }
        }
    },
    computed: {
        style() {
            const overlayColor = Color('black'); // Color(this.theme.color).darken(0.7)
            return {
                // backgroundColor: this.theme.headerBgColor || this.theme.color,
                // backgroundImage: `linear-gradient(0deg, ${overlayColor.hex()}cc, ${overlayColor.hex()}88), url(${this.image})`
                backgroundColor: 'black', // this.theme.headerBgColor || this.theme.color,
                backgroundImage: `linear-gradient(0deg, ${overlayColor.hex()}cc, ${overlayColor.hex()}88), url(${
                    this.image
                })`
            };
        }
    }
};
</script>

<style lang="scss" scoped>
.StepBackground {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    &.hasImage {
        background-size: cover;
        background-position: center;
    }
    &.blur {
        $extend: 24px;
        top: -$extend;
        right: -$extend;
        bottom: -$extend;
        left: -$extend;
        filter: blur(12px);
    }
}
</style>
